import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { OxComponentContainer } from "src/components/OxComponentContainer";
import { OxBackToTop } from "src/components/OxBackToTop";
import { WhoWeAre } from "src/components/Marketing/WhoWeAre";
import { Hero } from "src/components/Marketing/Hero";
import { OurApproach } from "src/components/Marketing/OurApproach";
import { AdvisoryPanel } from "src/components/Marketing/AdvisoryPanel";
import { OxPageHelmet } from "src/components/OxPageHelmet";

const Marketing1Page = (): JSX.Element => {
  const data = useStaticQuery(graphql`
    query {
      storyTabletImage: file(
        relativePath: { eq: "our-story/OurStory-Hero-Desktop.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      storyMobileImage: file(
        relativePath: { eq: "our-story/OurStory-Hero-Mobile.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `);

  const storyImages = [
    {
      ...data.storyTabletImage.childImageSharp.gatsbyImageData,
      media: `(min-width: 769px)`,
    },
    {
      ...data.storyMobileImage.childImageSharp.gatsbyImageData,
      media: `(max-width: 768px)`,
    },
  ];

  return (
    <>
      <OxPageHelmet
        title="1 | Ouronyx | Luxury Facial Aesthetics"
        description="Ouronyx is the next generation of facial aesthetics. We’re the first global aesthetics destination, helping people to self-optimise with highly personalised, non-surgical treatments, delivered by the world’s best-in-class Doctors."
        keywords="Aesthetic doctor, non surgical, bespoke treatments, luxurious, St James, London, inspiring, leaders"
      />

      <OxComponentContainer marginBottom={[40, 40, 40]}>
        <Hero />
      </OxComponentContainer>

      <OxComponentContainer marginBottom={[40, 40, 40]}>
        <WhoWeAre fluidImages={storyImages} />
      </OxComponentContainer>

      <OxComponentContainer marginBottom={[40, 40, 40]}>
        <AdvisoryPanel title="The Experts" primarySliderKey="meet-the-panel" />
      </OxComponentContainer>

      <OxComponentContainer marginBottom={[40, 40, 40]}>
        <OurApproach />
      </OxComponentContainer>

      <OxBackToTop />
    </>
  );
};

export default Marketing1Page;
