import React from "react";
import { OxFeatureBlock } from "src/components/OxFeatureBlock";
import { ERoutes } from "src/config/enums";
import { EOxCarouselItemType } from "src/components/OxCarousel";
import { useBreakpoints } from "src/hooks";
import { graphql, useStaticQuery } from "gatsby";
import {
  VIDEO_BIO_IMAN_LARGE_ID,
  VIDEO_BIO_IMAN_SMALL_ID,
  VIDEO_BIO_RAJ_LARGE_ID,
  VIDEO_BIO_RAJ_SMALL_ID,
  VIDEO_BIO_SABIKA_LARGE_ID,
  VIDEO_BIO_SABIKA_SMALL_ID,
} from "src/config/constants";
import { getMuxVideoLink } from "src/utils/muxHelpers";

type TProps = {
  title?: string;
  primarySliderKey: string;
};

export const AdvisoryPanel = ({
  title,
  primarySliderKey,
}: SCProps<"div", TProps>): JSX.Element => {
  const device = useBreakpoints();

  const data = useStaticQuery(graphql`
    query {
      primaryDesktopImage: file(
        relativePath: { eq: "marketing/advisory-panel/TheExperts1-Desktop.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      primaryMobileImage: file(
        relativePath: { eq: "marketing/advisory-panel/TheExperts1-Mobile.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      drImanQaDesktop: file(
        relativePath: {
          eq: "marketing/advisory-panel/Doctor-BIO-DrIman-Desktop.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      drRajQaDesktop: file(
        relativePath: {
          eq: "marketing/advisory-panel/Doctor-BIO-DrRaj-QA-Desktop.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      drSabikaQaDesktop: file(
        relativePath: {
          eq: "marketing/advisory-panel/Doctor-BIO-DrSabika-QA-Desktop.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      drSabikaQaMobile: file(
        relativePath: {
          eq: "marketing/advisory-panel/Doctor-BIO-DrSabika-QA-Mobile.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `);

  let sliderData = [
    {
      key: "meet-the-panel",
      title: "Ouronyx Advisory Panel",
      label: "Our Thought Leaders",
      description:
        "Bringing the industry’s key opinion leaders together, the Ouronyx advisory panel set the gold standard for facial aesthetics. Widely known as experts in their field, they have worked with advanced technologies, anatomical and psychological markers to advance facial aesthetics and set the incomparable Ouronyx standard of excellence.",
      linkUrl: ERoutes.AdvisoryPanel,
      linkLabel: "Meet the panel",
      carouselData: {
        type: EOxCarouselItemType.Video,
        video: {
          src: device.xs
            ? getMuxVideoLink(VIDEO_BIO_IMAN_SMALL_ID)
            : getMuxVideoLink(VIDEO_BIO_IMAN_LARGE_ID),
          props: {
            autoPlay: false,
            usePlayButtonOverlayer: true,
            controlsWhenPlay: true,
            overlayerButtonPosition: "center",
            poster: data.drImanQaDesktop.childImageSharp.gatsbyImageData.src,
          },
          settings: {
            playOnClick: true,
            controlsWhenPlay: true,
            fitVideo: true,
          },
        },
      },
    },
    {
      key: "dr-iman",
      label: "Industry Leaders",
      title: "Dr Iman",
      description:
        "Dr Iman Nurlin is known for her light touch and holistic approach to facial aesthetics. She strives for harmony and balance in her patients, by primarily addressing structural and textural issues.",
      linkUrl: ERoutes.AdvisoryPanel,
      linkLabel: "Meet the Panel",
      carouselData: {
        type: EOxCarouselItemType.Video,
        video: {
          src: device.xs
            ? getMuxVideoLink(VIDEO_BIO_IMAN_SMALL_ID)
            : getMuxVideoLink(VIDEO_BIO_IMAN_LARGE_ID),
          props: {
            autoPlay: false,
            usePlayButtonOverlayer: true,
            controlsWhenPlay: true,
            overlayerButtonPosition: "center",
            poster: data.drImanQaDesktop.childImageSharp.gatsbyImageData.src,
          },
          settings: {
            playOnClick: true,
            controlsWhenPlay: true,
            fitVideo: true,
          },
        },
      },
    },
    {
      key: "dr-sabika",
      title: "Dr Sabika",
      subtitle: "Doctor of cosmetics",
      description:
        "Dr Sabika Karim has amassed a loyal following thanks to her proprietary ‘liquid lift’: a transformative, non-surgical facelift. With her patient-led approach she aims to make them feel as empowered and beautiful as possible.",
      linkUrl: ERoutes.AdvisoryPanel,
      linkLabel: "Book a Consultation",
      carouselData: {
        type: EOxCarouselItemType.Video,
        video: {
          src: device.xs
            ? getMuxVideoLink(VIDEO_BIO_SABIKA_SMALL_ID)
            : getMuxVideoLink(VIDEO_BIO_SABIKA_LARGE_ID),
          props: {
            autoPlay: false,
            usePlayButtonOverlayer: true,
            controlsWhenPlay: true,
            overlayerButtonPosition: "center",
            poster: device.xs
              ? data.drSabikaQaMobile.childImageSharp.gatsbyImageData.src
              : data.drSabikaQaDesktop.childImageSharp.gatsbyImageData.src,
          },
          settings: {
            playOnClick: true,
            controlsWhenPlay: true,
            fitVideo: true,
          },
        },
      },
    },
    {
      key: "dr-raj",
      label: "Industry Leaders",
      title: "Dr Raj",
      description:
        "Known for his proprietary ‘illumination’ approach, Dr Raj Acquila works to highlight and shape the face. This specialist method aims to make a person more ‘mathematically beautiful’, bringing dimension and harmony to the face in powerfully positive ways.",
      linkUrl: ERoutes.AdvisoryPanel,
      linkLabel: "Book a Consultation",
      carouselData: {
        type: EOxCarouselItemType.Video,
        video: {
          src: device.xs
            ? getMuxVideoLink(VIDEO_BIO_RAJ_SMALL_ID)
            : getMuxVideoLink(VIDEO_BIO_RAJ_LARGE_ID),
          props: {
            autoPlay: false,
            usePlayButtonOverlayer: true,
            controlsWhenPlay: true,
            overlayerButtonPosition: "center",
            poster: data.drRajQaDesktop.childImageSharp.gatsbyImageData.src,
          },
          settings: {
            playOnClick: true,
            controlsWhenPlay: true,
            fitVideo: true,
          },
        },
      },
    },
  ];

  if (primarySliderKey) {
    // There currently seems to be a bug in the doctors slider which is causing display
    // issues if there are multiple slides and at least one is a video.  Temp fix to only
    // display one slider, flag below for easy switching for testing
    const onlyDisplayPrimarySlider = true;

    if (onlyDisplayPrimarySlider) {
      sliderData = sliderData.filter((slide) => slide.key === primarySliderKey);
    } else {
      // Sort so primary slider is first
      sliderData.sort((a, b) => {
        return a.key == primarySliderKey
          ? -1
          : b.key == primarySliderKey
          ? 1
          : 0;
      });
    }
  }

  return (
    <OxFeatureBlock
      lineHeaderTitle={title ? title : "Meet Our Advisory Panel"}
      sliderData={sliderData}
    />
  );
};
