import React from "react";
import { ERoutes } from "src/config/enums";
import { EOxStoryVariant, OxStory } from "src/components/OxStory";
import { graphql, useStaticQuery } from "gatsby";

export const OurApproach = (): JSX.Element => {
  const data = useStaticQuery(graphql`
    query {
      mainImage: file(
        relativePath: { eq: "marketing/FemaleSquareBAndW1-Desktop.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `);

  const images = [
    {
      ...data.mainImage.childImageSharp.gatsbyImageData,
    },
  ];

  return (
    <OxStory
      fluidImagesArray={images}
      label="OUR APPROACH"
      title="Personalised experience"
      description="Ouronyx delivers a unique experience with a team of experts guiding you throughout the journey. Our treatment plans are co-created with the patient to best understand their hopes and fears, and to deliver the most beautifully authentic version of themselves possible. Our holistic approach to the face aims to harmonise all the elements that make up a person’s unique and individual beauty. Our dedicated team of doctors employ a proprietary approach; using aesthetic and anatomical assessments to co-create the ideal treatment for every individual."
      linkTitle="Book a Consultation"
      linkUrl={ERoutes.Appointment}
      variant={EOxStoryVariant.ImageRight}
    />
  );
};
