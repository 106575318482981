import React from "react";
import { OxBanner } from "src/components/OxBanner";
import { graphql, useStaticQuery } from "gatsby";
import { TBannerItem } from "src/components/OxBanner/components/OxBannerItem";

type TProps = {
  imageAlt?: boolean;
};

export const Hero = (props: TProps): JSX.Element => {
  const data = useStaticQuery(graphql`
    query {
      heroPrimaryDesktopImage: file(
        relativePath: { eq: "marketing/Hero1-Desktop.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      heroPrimaryMobileImage: file(
        relativePath: { eq: "marketing/Hero1-Mobile.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      heroAltDesktopImage: file(
        relativePath: { eq: "marketing/Hero2-Desktop.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      heroAltMobileImage: file(
        relativePath: { eq: "marketing/Hero2-Mobile.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `);

  const heroPrimaryImages = [
    {
      ...data.heroPrimaryMobileImage.childImageSharp.gatsbyImageData,
      media: `(max-width: 767px)`,
    },
    {
      ...data.heroPrimaryDesktopImage.childImageSharp.gatsbyImageData,
      media: `(min-width: 768px)`,
    },
  ];

  const heroAltImages = [
    {
      ...data.heroAltMobileImage.childImageSharp.gatsbyImageData,
      media: `(max-width: 767px)`,
    },
    {
      ...data.heroAltDesktopImage.childImageSharp.gatsbyImageData,
      media: `(min-width: 768px)`,
    },
  ];

  const images = props.imageAlt ? heroAltImages : heroPrimaryImages;

  const heroItems: TBannerItem[] = [
    {
      titleArray: [
        { sentence: ["FIND"] },
        { sentence: ["STRENGTH"] },
        { sentence: ["IN"] },
        { sentence: ["BALANCE"] },
      ],
      subtitle: [["Welcome to the next generation"], ["of facial aesthetics."]],
      fluidImages: images,
      isOverlaidTextBlack: true,
    },
  ];

  return <OxBanner items={heroItems} />;
};
