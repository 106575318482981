import React from "react";
import { ERoutes } from "src/config/enums";
import { EOxStoryVariant, OxStory } from "src/components/OxStory";

type TProps = {
  fluidImages: any;
  title?: string;
  description?: string;
};

export const WhoWeAre = (props: TProps): JSX.Element => {
  return (
    <OxStory
      fluidImagesArray={props.fluidImages}
      label="WHO WE ARE"
      title={props.title ? props.title : "Ouronyx Opens on 12th April"}
      description={
        props.description
          ? props.description
          : "Ouronyx takes a fresh approach to facial injectables. We look forward to welcoming you into our new London destination at 20 St James's Street, where we will guide you through a personal journey of next generation aesthetics. We offer a bespoke, cutting-edge approach led by unparalleled doctors who work hand in hand with you to co-create a perfect plan. The results are unique, with outcomes that illuminate the power of both your inner and outer beauty."
      }
      linkTitle="Book a Consultation"
      linkUrl={ERoutes.Appointment}
      variant={EOxStoryVariant.ImageRight}
    />
  );
};
